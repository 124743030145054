import React, { useEffect } from "react";
import "../styles/FormPopup.css";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faSpinner } from "@fortawesome/free-solid-svg-icons";

const {
  REACT_APP_API_ENDPOINT,
  REACT_APP_API_SWAGGER_ENDPOINT,
  REACT_APP_AUTH_USERNAME,
  REACT_APP_AUTH_PASSWORD,
} = process.env;

const FormPopup = ({ setShowPopup, showPopup, email, firstName, lastName }) => {
  const [brand, setBrand] = React.useState("");
  const [frequency, setFrequency] = React.useState("");
  const [concerns, setConcerns] = React.useState("");
  const [submitted, setSubmitted] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [brands, setBrands] = React.useState([]);

  const getBrandsName = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_API_SWAGGER_ENDPOINT}/v1-admin/getSupportedBrands`,
        {
          auth: {
            username: REACT_APP_AUTH_USERNAME,
            password: REACT_APP_AUTH_PASSWORD,
          },
        }
      );
      setBrands(response.data);
    } catch (error) {
      console.error("Failed to get brands:", error);
    }
  };

  useEffect(() => {
    getBrandsName();
  }, []);

  const allFieldsFilled = () => {
    return brand !== "" && frequency !== "" && concerns !== "";
  };

  if (!showPopup) {
    return null;
  }

  const closePopup = () => {
    setShowPopup(false);
    setSubmitted(false);
  };

  const submitForm = async (e) => {
    e.preventDefault();
    if (submitted || loading) return;

    setSubmitted(true);
    setLoading(true);

    try {
      const response = await axios.post(`${REACT_APP_API_ENDPOINT}/form`, {
        email,
        firstName,
        lastName,
        brand,
        frequency,
        concerns,
      });
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      setSubmitted(false);
    }
  };

  const sendEmail = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${REACT_APP_API_ENDPOINT}/email`, {
        email,
        firstName,
      });
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  const submit = (e) => {
    e.preventDefault();
    submitForm(e);
    sendEmail(e);
  };

  if (loading) {
    return (
      <div className="popupwindow">
        <div className="popup loading">
          <FontAwesomeIcon icon={faSpinner} size="2xl" spin />
        </div>

        <div className="overlay"></div>
      </div>
    );
  }

  if (submitted && !loading) {
    return (
      <div className="popupwindow">
        <div className="popup">
          <div className="close-icon-div">
            <FontAwesomeIcon
              icon={faXmark}
              className="close-icon"
              onClick={closePopup}
              size="lg"
            />
          </div>

          <div className="image-section" onClick={closePopup}></div>

          <h2>Thank you,</h2>
          <h2>{firstName.toUpperCase()}!</h2>
          <br />
          <p>
            We will be in touch with you shortly. In the meantime, stay tuned
            for updates via{" "}
            <a
              href="https://www.instagram.com/hyffinapp/?locale=en-GB"
              target="_blank"
              rel="noreferrer"
            >
              Instagram
            </a>
            .
          </p>

          <button
            className="join-btn black"
            onClick={() => {
              window.location.reload();
              setSubmitted(false);
            }}
          >
            Return
          </button>

          <div className="form-brand-icon">
            <img src="brand-icon.png" alt="brand-icon" />
          </div>
        </div>
        <div className="overlay" onClick={closePopup}></div>
      </div>
    );
  }

  return (
    <div className="popupwindow">
      <div className="popup">
        <div className="close-icon-div">
          <FontAwesomeIcon
            icon={faXmark}
            className="close-icon"
            onClick={closePopup}
            size="lg"
          />
        </div>

        <div className="image-section" onClick={closePopup}></div>

        <h2>Great to see you,</h2>
        <h2>{firstName.toUpperCase()}!</h2>

        <br />

        <form className="form" onSubmit={submit}>
          <div className="form-group">
            <label htmlFor="brands">
              What brands of luxury bags are you most interested in trading?
            </label>
            <select
              id="brands"
              name="brands"
              className="form-control"
              value={brand}
              onChange={(e) => setBrand(e.target.value)}
            >
              <option value="" disabled>
                Select one
              </option>
              {brands.map((brand) => (
                <option key={brand.brandEnum} value={brand.brandEnum}>
                  {brand.brandName}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="frequency">
              How often do you buy or sell luxury bags?
            </label>
            <select
              id="frequency"
              name="frequency"
              className="form-control"
              value={frequency}
              onChange={(e) => setFrequency(e.target.value)}
            >
              <option value="" disabled>
                Select one
              </option>
              <option value="weekly">Weekly</option>
              <option value="monthly">Monthly</option>
              <option value="every_few_months">Every few months</option>
              <option value="once_a_year">Once a year</option>
              <option value="rarely">Rarely</option>
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="concerns">
              What is your primary concern when trading luxury bags online?
            </label>
            <select
              id="concerns"
              name="concerns"
              className="form-control"
              value={concerns}
              onChange={(e) => setConcerns(e.target.value)}
            >
              <option value="" disabled>
                Select one
              </option>
              <option value="authenticity">Authenticity of the bags</option>
              <option value="security">Security of transactions</option>
              <option value="privacy">Privacy of personal information</option>
              <option value="ease_of_use">Ease of use of the app</option>
              <option value="quality">Quality of the bags</option>
            </select>
          </div>
          <button
            type="submit"
            className={`join-btn ${allFieldsFilled() ? "black" : "grey"}`}
            disabled={!allFieldsFilled()}
          >
            Submit
          </button>
        </form>
      </div>
      <div className="overlay" onClick={closePopup}></div>
    </div>
  );
};

export default FormPopup;
