import { useState, useEffect } from "react";
import axios from "axios";
const { REACT_APP_API_ENDPOINT } = process.env;

const useAuth = () => {
  const [adminEmail, setAdminEmail] = useState("");
  const [password, setPassword] = useState("");
  const [adminData, setAdminData] = useState(null);
  const [error, setError] = useState("");
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const allAdminFieldsFilled = () => {
    return validateEmail(adminEmail) && password !== "";
  };

  const submitAdminLogin = async (e) => {
    e.preventDefault();
    setError("");
    try {
      const res = await axios.post(`${REACT_APP_API_ENDPOINT}/login`, {
        email: adminEmail,
        password,
      });
      setAdminData(res.data);
      localStorage.setItem("token", res.data.token);
      localStorage.setItem("id", res.data.admin._id);
      localStorage.setItem("view", "home");
      setIsLoggingIn(true);

      setTimeout(() => {
        window.location.href = `/dashboard/${res.data.admin._id}`;
      }, 1500);
    } catch (error) {
      console.log(error);

      if (error.response) {
        setError(error.response.data.error);
      } else {
        setError("An unexpected error occurred. Please try again.");
      }
    }
  };

  useEffect(() => {
    const checkToken = () => {
      const token = localStorage.getItem("token");
      if (token) {
        setAdminData({ token });
      }
    };
    checkToken();
  }, []);

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("id");
    setAdminData(null);
    window.location.href = "/admin";
  };

  return {
    adminEmail,
    setAdminEmail,
    password,
    setPassword,
    adminData,
    error,
    submitAdminLogin,
    logout,
    allAdminFieldsFilled,
    isLoggingIn,
  };
};

export default useAuth;
