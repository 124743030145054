import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Home from "./pages/Home";
import Admin from "./pages/Admin";
import Dashboard from "./pages/Dashboard";
import "@progress/kendo-theme-default/dist/all.css";

import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  const token = localStorage.getItem("token");
  const id = localStorage.getItem("id");

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          {token ? (
            <>
              <Route path={`/dashboard/:id`} element={<Dashboard />} />
              <Route
                path="/admin"
                element={<Navigate to={`/dashboard/${id}`} replace />}
              />
            </>
          ) : (
            <>
              <Route path="/admin" element={<Admin />} />
              <Route
                path={`/dashboard/${id}`}
                element={<Navigate to="/admin" replace />}
              />
            </>
          )}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
