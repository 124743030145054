import React, { useState, useEffect } from "react";
import "../styles/FormPopup.css";
import axios from "axios";
import { Form, InputGroup } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import FormData from "form-data";
import CheckImage from "./CheckImage";

const {
  REACT_APP_API_SWAGGER_ENDPOINT,
  REACT_APP_AUTH_USERNAME,
  REACT_APP_AUTH_PASSWORD,
} = process.env;

const AddModel = () => {
  const [addModelData, setAddModelData] = useState({
    brandName: "",
    model: "",
    photo: null,
  });
  const [brands, setBrands] = useState([]);
  const [addModelStatus, setAddModelStatus] = useState({
    allFieldsFilled: false,
    modelAdded: false,
  });

  const [getPhotoData, setGetPhotoData] = useState({
    modelId: "",
    fetchedPhoto: null,
  });
  const [getPhotoStatus, setGetPhotoStatus] = useState({
    photoIdFilled: false,
    photoFetched: false,
  });

  const [getModelData, setGetModelData] = useState({
    brandNameForModel: "",
    fetchedData: null,
  });
  const [getModelStatus, setGetModelStatus] = useState({
    modelPhotoFilled: false,
    searchModelList: false,
    loadingFetch: true,
  });
  const [showPopup, setShowPopup] = useState(false);
  const [imgUrl, setImgUrl] = useState("");

  useEffect(() => {
    const { brandName, model } = addModelData;
    const { modelId } = getPhotoData;
    const { brandNameForModel } = getModelData;

    setAddModelStatus((prevStatus) => ({
      ...prevStatus,
      allFieldsFilled: Boolean(brandName && model),
    }));

    setGetPhotoStatus((prevStatus) => ({
      ...prevStatus,
      photoIdFilled: Boolean(modelId),
    }));

    setGetModelStatus((prevStatus) => ({
      ...prevStatus,
      modelPhotoFilled: Boolean(brandNameForModel),
    }));

    getBrandsName();
  }, [addModelData, getPhotoData, getModelData]);

  const getBrandsName = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_API_SWAGGER_ENDPOINT}/v1-admin/getSupportedBrands`,
        {
          auth: {
            username: REACT_APP_AUTH_USERNAME,
            password: REACT_APP_AUTH_PASSWORD,
          },
        }
      );
      setBrands(response.data);
    } catch (error) {
      console.error("Failed to get brands:", error);
    }
  };

  const handleAddModelChange = (e) => {
    const { name, value, files } = e.target;
    setAddModelData({
      ...addModelData,
      [name]: files ? files[0] : value,
    });
  };

  const handleGetPhotoChange = (e) => {
    const { name, value } = e.target;
    setGetPhotoData({
      ...getPhotoData,
      [name]: value,
    });
  };

  const handleGetModelChange = (e) => {
    const { name, value } = e.target;
    setGetModelData({
      ...getModelData,
      [name]: value,
    });
  };

  const submitForm = async (e) => {
    if (e) e.preventDefault();

    const { brandName, model, photo } = addModelData;
    const data = new FormData();

    data.append("brandName", brandName);
    data.append("model", model);
    if (photo) {
      data.append("photo", photo);
    }

    const config = {
      auth: {
        username: REACT_APP_AUTH_USERNAME,
        password: REACT_APP_AUTH_PASSWORD,
      },
    };

    try {
      await axios.post(
        `${REACT_APP_API_SWAGGER_ENDPOINT}/v1-admin/addBagModel`,
        data,
        config
      );
      setAddModelStatus((prevStatus) => ({ ...prevStatus, modelAdded: true }));
      toast.success("Model added successfully!");
    } catch (error) {
      toast.error("Failed to add model. Please try again.");
      console.error("Failed to add model:", error);
    }
  };

  const replaceImage = async (modelId, file) => {
    if (!file) {
      toast.error("No file selected.");
      return;
    }

    const data = new FormData();
    data.append("modelId", modelId);
    data.append("photo", file);

    const config = {
      auth: {
        username: REACT_APP_AUTH_USERNAME,
        password: REACT_APP_AUTH_PASSWORD,
      },
    };

    try {
      await axios.post(
        `${REACT_APP_API_SWAGGER_ENDPOINT}/v1-admin/addDefaultPhoto`,
        data,
        config
      );
      toast.success("Photo updated successfully!");
    } catch (error) {
      toast.error("Failed to update photo. Please try again.");
      console.error("Failed to update photo:", error);
    }
  };

  const getPhoto = async (e) => {
    e.preventDefault();
    const { modelId } = getPhotoData;

    try {
      const response = await axios.get(
        `${REACT_APP_API_SWAGGER_ENDPOINT}/v1-admin/getDefaultPhoto?modelId=${modelId}`,
        {
          responseType: "arraybuffer",
          auth: {
            username: REACT_APP_AUTH_USERNAME,
            password: REACT_APP_AUTH_PASSWORD,
          },
        }
      );

      const base64String = btoa(
        new Uint8Array(response.data).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          ""
        )
      );
      setGetPhotoData({
        ...getPhotoData,
        fetchedPhoto: `data:image/jpeg;base64,${base64String}`,
      });
      setGetPhotoStatus({ ...getPhotoStatus, photoFetched: true });
      toast.success("Photo fetched successfully!");
    } catch (error) {
      toast.error("Failed to get photo. Please try again.");
      console.error("Failed to get photo:", error);
    }
  };

  const getModelPhoto = async (e = null) => {
    if (e) e.preventDefault();
    const { brandNameForModel } = getModelData;

    try {
      const response = await axios.get(
        `${REACT_APP_API_SWAGGER_ENDPOINT}/v1-admin/modelsForBrand?brandName=${brandNameForModel}`,
        {
          auth: {
            username: REACT_APP_AUTH_USERNAME,
            password: REACT_APP_AUTH_PASSWORD,
          },
        }
      );

      const fetchedData = response.data.map((modelObj) => {
        if (modelObj.photoUrl) {
          modelObj.photoUrl = `${modelObj.photoUrl}?t=${new Date().getTime()}`;
        }
        return modelObj;
      });

      if (fetchedData.length === 0) {
        toast.info("No models found for this brand.");
      }

      setGetModelData({ ...getModelData, fetchedData });
      setGetModelStatus({
        ...getModelStatus,
        searchModelList: true,
        loadingFetch: false,
      });
      toast.success("Model list fetched successfully!");
    } catch (error) {
      toast.error("Failed to get model list. Please try again.");
      console.error("Failed to get photo:", error);
    }
  };

  const refreshModelPhoto = () => {
    getModelPhoto();
  };

  return (
    <div className="Product">
      <ToastContainer position="top-center" />
      <CheckImage
        setShowPopup={setShowPopup}
        showPopup={showPopup}
        imgUrl={imgUrl}
      />
      <div className="row">
        <div className="col-lg-5">
          <div className="add-model">
            <h2>Add Model</h2>
            <Form onSubmit={submitForm}>
              <Form.Group className="mb-3" controlId="formBrandName">
                <Form.Label>Brand Name</Form.Label>
                <InputGroup className="mb-3">
                  <select
                    id="brands"
                    name="brandName"
                    className="form-control"
                    value={addModelData.brandName}
                    onChange={handleAddModelChange}
                    required
                  >
                    <option value="" disabled>
                      Select one
                    </option>
                    {brands.map((brand) => (
                      <option key={brand.brandEnum} value={brand.brandEnum}>
                        {brand.brandName}
                      </option>
                    ))}
                  </select>
                </InputGroup>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formModelName">
                <Form.Label>Model Name</Form.Label>
                <InputGroup className="mb-3">
                  <Form.Control
                    placeholder="Enter model name"
                    name="model"
                    value={addModelData.model}
                    onChange={handleAddModelChange}
                    required
                  />
                </InputGroup>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formPhoto">
                <Form.Label>jpg, jpeg image</Form.Label>
                <InputGroup className="mb-3">
                  <Form.Control
                    type="file"
                    name="photo"
                    onChange={handleAddModelChange}
                    accept=".jpg, .jpeg"
                  />
                </InputGroup>
              </Form.Group>
              {addModelData.photo && (
                <div className="preview-photo">
                  <img
                    src={URL.createObjectURL(addModelData.photo)}
                    alt="preview"
                    style={{
                      width: "50%",
                      borderRadius: "10px",
                      textAlign: "center",
                    }}
                    onClick={() => {
                      setShowPopup(true);
                      setImgUrl(URL.createObjectURL(addModelData.photo));
                    }}
                  />
                </div>
              )}
              <button
                type="submit"
                className={`join-btn ${
                  addModelStatus.allFieldsFilled ? "black" : "grey"
                }`}
              >
                {addModelStatus.modelAdded ? (
                  <img
                    className="check-mark"
                    src="/check.gif"
                    alt="check"
                    style={{ width: "22px" }}
                  />
                ) : (
                  "Add Now!"
                )}
              </button>
            </Form>
          </div>
        </div>

        <div className="col-lg-5">
          <div className="add-model">
            <h2>Get Photo</h2>
            <Form onSubmit={getPhoto}>
              <Form.Group className="mb-3" controlId="formPhotoId">
                <Form.Label>Photo Id</Form.Label>
                <InputGroup className="mb-3">
                  <Form.Control
                    placeholder="Enter model id"
                    name="modelId"
                    value={getPhotoData.modelId}
                    onChange={handleGetPhotoChange}
                    required
                  />
                </InputGroup>
              </Form.Group>
              <button
                type="submit"
                className={`join-btn ${
                  getPhotoStatus.photoIdFilled ? "black" : "grey"
                }`}
              >
                {getPhotoStatus.photoFetched ? (
                  <img
                    className="check-mark"
                    src="/check.gif"
                    alt="check"
                    style={{ width: "22px" }}
                  />
                ) : (
                  "Search"
                )}
              </button>
            </Form>

            {getPhotoData.fetchedPhoto && (
              <div className="fetched-photo">
                <div
                  className="cancel-photo"
                  onClick={() =>
                    setGetPhotoData({ ...getPhotoData, fetchedPhoto: null })
                  }
                >
                  <FontAwesomeIcon icon={faXmark} size="lg" />
                </div>
                <img
                  src={getPhotoData.fetchedPhoto}
                  alt="Fetched from API"
                  style={{
                    width: "50%",
                    borderRadius: "10px",
                  }}
                  onClick={() => {
                    setShowPopup(true);
                    setImgUrl(getPhotoData.fetchedPhoto);
                  }}
                />
              </div>
            )}
          </div>
        </div>

        <div className="col-lg-12" style={{ marginTop: "15px" }}>
          <div className="add-model">
            <h2>
              Model list{" "}
              {getModelStatus.searchModelList &&
                getModelData.fetchedData &&
                getModelData.fetchedData.length > 0 &&
                `: ${getModelData.fetchedData[0].brandName} (${getModelData.fetchedData.length})`}
            </h2>

            <div className="return-btn-div">
              {getModelStatus.searchModelList ? (
                <>
                  <button
                    onClick={() => {
                      setGetModelStatus({
                        ...getModelStatus,
                        searchModelList: false,
                        loadingFetch: true,
                      });
                    }}
                  >
                    Return
                  </button>
                  <button
                    onClick={refreshModelPhoto}
                    style={{ marginLeft: "10px" }}
                  >
                    <FontAwesomeIcon icon={faSyncAlt} size="lg" />
                  </button>
                </>
              ) : null}
            </div>

            {getModelStatus.searchModelList ? (
              <div className="model-table-wrapper">
                {getModelData.fetchedData &&
                getModelData.fetchedData.length > 0 ? (
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Model Image</th>
                          <th scope="col">Model Name</th>
                          <th scope="col">Model ID</th>
                          <th scope="col">View Image</th>
                          <th scope="col">Replace Image</th>
                        </tr>
                      </thead>

                      <tbody>
                        {getModelData.fetchedData.map((modelObj, index) => (
                          <tr key={modelObj.modelId}>
                            <th scope="row">{index + 1}</th>
                            <td>
                              <div className="image-container">
                                <img
                                  className={`model-image ${
                                    modelObj.photoUrl ? "" : "no-image"
                                  }`}
                                  src={
                                    modelObj.photoUrl
                                      ? modelObj.photoUrl
                                      : "/no-image.png"
                                  }
                                  alt={modelObj.modelName}
                                  style={{
                                    width: "100px",
                                    borderRadius: "10px",
                                    cursor: "pointer",
                                  }}
                                />
                              </div>
                              <input
                                type="file"
                                id={`file-input-${modelObj.modelId}`}
                                style={{ display: "none" }}
                                accept=".jpg, .jpeg"
                                onChange={(e) => {
                                  const file = e.target.files[0];
                                  if (file) {
                                    replaceImage(modelObj.modelId, file);
                                  }
                                }}
                              />
                            </td>

                            <td>{modelObj.modelName}</td>
                            <td>{modelObj.modelId}</td>
                            <td>
                              {modelObj.photoUrl ? (
                                <button
                                  className="view-button"
                                  onClick={() => {
                                    setShowPopup(true);
                                    setImgUrl(modelObj.photoUrl);
                                  }}
                                >
                                  View Image
                                </button>
                              ) : (
                                <span className="no-image-text">
                                  No Image Available
                                </span>
                              )}
                            </td>
                            <td>
                              <button
                                className="replace-button"
                                onClick={() => {
                                  const fileInput = document.getElementById(
                                    `file-input-${modelObj.modelId}`
                                  );
                                  if (fileInput) {
                                    fileInput.click();
                                  }
                                }}
                              >
                                Replace Image
                              </button>
                              <input
                                type="file"
                                id={`file-input-${modelObj.modelId}`}
                                style={{ display: "none" }}
                                accept=".jpg, .jpeg"
                                onChange={(e) => {
                                  const file = e.target.files[0];
                                  if (file) {
                                    replaceImage(modelObj.modelId, file);
                                  }
                                }}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <p>No models found.</p>
                )}
              </div>
            ) : (
              <Form onSubmit={getModelPhoto}>
                <Form.Group className="mb-3" controlId="formModelPhoto">
                  <Form.Label>Brand Name</Form.Label>
                  <InputGroup className="mb-3">
                    <select
                      id="brands"
                      name="brandNameForModel"
                      className="form-control"
                      value={getModelData.brandNameForModel}
                      onChange={handleGetModelChange}
                      required
                    >
                      <option value="" disabled>
                        Select one
                      </option>
                      {brands.map((brand) => (
                        <option key={brand.brandEnum} value={brand.brandEnum}>
                          {brand.brandName}
                        </option>
                      ))}
                    </select>
                  </InputGroup>
                </Form.Group>
                <button
                  type="submit"
                  className={`join-btn ${
                    getModelStatus.modelPhotoFilled ? "black" : "grey"
                  }`}
                >
                  {getModelStatus.searchModelList ? (
                    <img
                      className="check-mark"
                      src="/check.gif"
                      alt="check"
                      style={{ width: "22px" }}
                    />
                  ) : (
                    "Search"
                  )}
                </button>
              </Form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddModel;
