import React from "react";
import "../styles/FormPopup.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

const CheckImage = ({ setShowPopup, showPopup, imgUrl }) => {
  if (!showPopup) {
    return null;
  }

  const closePopup = () => {
    setShowPopup(false);
  };

  return (
    <div className="popupwindow">
      <div className="popup large-img">
        <div className="close-icon-div">
          <FontAwesomeIcon
            icon={faXmark}
            className="close-icon"
            onClick={closePopup}
            size="lg"
          />
        </div>
        <div className="img-div">
          <img src={imgUrl} alt="Check" className="check-image" />
        </div>
      </div>
      <div className="overlay" onClick={closePopup}></div>
    </div>
  );
};

export default CheckImage;
