import React, { useState } from "react";
import FormPopup from "./FormPopup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faApple, faAndroid } from "@fortawesome/free-brands-svg-icons";

const UserRegistrationForm = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const allFieldsFilled = () => {
    return validateEmail(email) && firstName !== "" && lastName !== "";
  };

  const openPopup = () => {
    setShowPopup(true);
  };

  return (
    <>
      <h1>Don't sell your bags,</h1>
      <h1>trade them!</h1>
      <p className="grey-text">Use your bag as currency.</p>
      <hr />
      <h3>Get early access!</h3>
      <p className="grey-text">
        Be one of the first to create a profile and trade your bags.
      </p>
      <label htmlFor="name" className="form-label">
        Full name
      </label>

      <div className="form-row">
        <div className="col">
          <input
            type="text"
            className="form-control"
            placeholder="First name"
            onChange={(e) => setFirstName(e.target.value)}
          />
        </div>
        <div className="col">
          <input
            type="text"
            className="form-control"
            placeholder="Last name"
            onChange={(e) => setLastName(e.target.value)}
          />
        </div>
      </div>

      <label htmlFor="email" className="form-label">
        Email
      </label>
      <input
        type="email"
        name="email"
        placeholder="Enter your email"
        className="form-control"
        onChange={(e) => setEmail(e.target.value)}
      />
      <button
        className={`join-btn ${allFieldsFilled() ? "black" : "grey"}`}
        onClick={openPopup}
        disabled={!allFieldsFilled()}
      >
        Continue
      </button>
      <FormPopup
        showPopup={showPopup}
        setShowPopup={setShowPopup}
        email={email}
        firstName={firstName}
        lastName={lastName}
      />
      <div className="platforms">
        <p>Available on:</p>
        <div>
          <FontAwesomeIcon icon={faApple} size="2x" className="icon" />
          <FontAwesomeIcon
            icon={faAndroid}
            size="2x"
            className="icon"
            color="#a4c639"
          />
        </div>
      </div>
    </>
  );
};

export default UserRegistrationForm;
