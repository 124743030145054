import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import useAuth from "../hooks/useAuth";
import "../styles/Header.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleUser } from "@fortawesome/free-solid-svg-icons";
import ChangePassword from "./ChangePassword";

function Header(props) {
  const { logout } = useAuth();
  const [showPopup, setShowPopup] = React.useState(false);

  const openPopup = () => {
    setShowPopup(true);
  };

  return (
    <>
      <Navbar expand="lg" className="bg-body-tertiary">
        <Container style={{ padding: "0px 20px" }}>
          <Navbar.Brand href="/admin">
            <img
              src="https://mingdocs.s3.ca-central-1.amazonaws.com/hyffin-clear.png"
              alt=""
              style={{ width: "35px" }}
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <NavDropdown
                title={
                  <>
                    <FontAwesomeIcon icon={faCircleUser} size="lg" />{" "}
                    {props.name}
                  </>
                }
                id="basic-nav-dropdown"
              >
                <NavDropdown.Item onClick={openPopup}>
                  Change password
                </NavDropdown.Item>
                <NavDropdown.Item onClick={logout}>Logout</NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {showPopup && <ChangePassword setShowPopup={setShowPopup} />}
    </>
  );
}

export default Header;
