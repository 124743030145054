import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import axios from "axios";
import { useParams } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "../styles/Dashboard.css";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faUsers,
  faShoppingCart,
  faBox,
  faTag,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import AddModelPopup from "../components/AddModel";
import { useNavigate } from "react-router-dom";

const { REACT_APP_API_ENDPOINT } = process.env;

const Dashboard = () => {
  const { id } = useParams();
  const [adminInfo, setAdminInfo] = useState({});
  const [admins, setAdmins] = useState([]);
  const [forms, setForms] = useState([]);
  const [formStats, setFormStats] = useState({});
  const [todayWaitlistIncrease, setTodayWaitlistIncrease] = useState(0);
  const [currentView, setCurrentView] = useState("stats");
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      const [adminRes, adminsRes, formsRes, formStatsRes, waitlistRes] =
        await Promise.all([
          axios.get(`${REACT_APP_API_ENDPOINT}/admin/${id}`),
          axios.get(`${REACT_APP_API_ENDPOINT}/admins`),
          axios.get(`${REACT_APP_API_ENDPOINT}/forms`),
          axios.get(`${REACT_APP_API_ENDPOINT}/form-stats`),
          axios.get(`${REACT_APP_API_ENDPOINT}/today-waitlist-increase`),
        ]);

      setAdminInfo(adminRes.data.admin);
      setAdmins(adminsRes.data.admins);
      setForms(formsRes.data.forms);
      setFormStats(formStatsRes.data || {});
      setTodayWaitlistIncrease(waitlistRes.data.increase);
      setLoading(false);
    } catch (error) {
      console.log("API fetch error:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    document.title = `${adminInfo.firstName || "Admin"} - Dashboard`;
    fetchData();
  }, [currentView, adminInfo.firstName]);

  const renderProgressTable = (stats = [], total) => (
    <div style={{ marginTop: "30px" }}>
      {stats.slice(0, 3).map(([key, value], index) => (
        <div className="result-data-div" key={index}>
          {index + 1}. {key}
        </div>
      ))}
    </div>
  );

  const [showPopup, setShowPopup] = React.useState(false);

  const view = localStorage.getItem("view");

  const viewProduct = () => {
    localStorage.setItem("view", "product");
    window.location.reload();
  };

  const viewHome = () => {
    localStorage.setItem("view", "home");
    window.location.reload();
  };

  return (
    <div className="Dashboard">
      <Header name={adminInfo.firstName} />
      <div className="dashboard-container d-flex">
        <Sidebar className={`sidebar`} defaultCollapsed={true}>
          <Menu>
            <MenuItem
              icon={<FontAwesomeIcon icon={faHome} />}
              onClick={() => viewHome()}
            >
              {" "}
              <span className="sidebar-title">Home</span>{" "}
            </MenuItem>
            <MenuItem
              className="inactive"
              icon={<FontAwesomeIcon icon={faUsers} />}
            >
              {" "}
              <span className="sidebar-title">Customers</span>{" "}
            </MenuItem>
            <MenuItem
              className="inactive"
              icon={<FontAwesomeIcon icon={faShoppingCart} />}
            >
              {" "}
              <span className="sidebar-title">Orders</span>{" "}
            </MenuItem>

            <MenuItem
              icon={<FontAwesomeIcon icon={faBox} />}
              onClick={() => viewProduct()}
            >
              {" "}
              <span className="sidebar-title">Products</span>{" "}
            </MenuItem>

            <MenuItem
              className="inactive"
              icon={<FontAwesomeIcon icon={faTag} />}
            >
              {" "}
              <span className="sidebar-title">
                Discounts and promotions
              </span>{" "}
            </MenuItem>
          </Menu>
        </Sidebar>

        {loading ? (
          <div className="dashboard-container-right">
            <div className="loading-div">
              <div className="text-center">
                <FontAwesomeIcon icon={faSpinner} spin size="2x" />
              </div>
            </div>
          </div>
        ) : null}

        {!loading && view === "home" ? (
          <div className="dashboard-container-right">
            <>
              <br />
              <div className="row">
                <div className="waitlist row">
                  <div className="col-lg-3">
                    <div
                      className="total-chart"
                      onClick={() => setCurrentView("waitlist")}
                    >
                      <div className="block-container">
                        <div className="blue-box"></div>
                        <span>Total waitlist</span>
                      </div>

                      <div>
                        <div>
                          <span className="total-number">{forms.length}</span>
                          <span className="increase">
                            {" "}
                            +{todayWaitlistIncrease}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 stats">
                    <div className="total-chart">
                      <div className="block-container">
                        <div className="blue-box"></div>
                        <span>Total admin</span>
                      </div>
                      <div>
                        <span className="total-number">{admins.length}</span>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-3 stats">
                    <div className="total-chart">
                      <div className="block-container">
                        <div className="blue-box"></div>
                        <span>Total bags</span>
                      </div>

                      <div>
                        <span className="total-number">200</span>
                      </div>
                    </div>
                  </div>
                </div>

                {/* stats part */}

                <div className="waitlist row">
                  <div className="col-lg-3">
                    <div
                      className="total-chart"
                      onClick={() => setCurrentView("waitlist")}
                    >
                      <div className="block-container">
                        <div className="blue-box"></div>
                        <span>Most liked brand</span>
                      </div>

                      <div>
                        {formStats.brand
                          ? renderProgressTable(formStats.brand, forms.length)
                          : "No data available"}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 stats">
                    <div className="total-chart">
                      <div className="block-container">
                        <div className="blue-box"></div>
                        <span>Frequency</span>
                      </div>

                      <div>
                        {formStats.frequency
                          ? renderProgressTable(
                              formStats.frequency,
                              forms.length
                            )
                          : "No data available"}
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-3 stats">
                    <div className="total-chart">
                      <div className="block-container">
                        <div className="blue-box"></div>
                        <span>Most concerns</span>
                      </div>

                      <div>
                        {formStats.concerns
                          ? renderProgressTable(
                              formStats.concerns,
                              forms.length
                            )
                          : "No data available"}
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-3">
                    <div>
                      <div
                        className="total-chart green"
                        onClick={() => setCurrentView("waitlist")}
                      >
                        <div className="block-container">
                          <span>Total trades</span>
                        </div>

                        <div style={{ marginTop: "10px" }}>
                          <span># 120</span>
                        </div>
                      </div>
                    </div>

                    <div>
                      <div
                        className="total-chart orange"
                        onClick={() => setCurrentView("waitlist")}
                      >
                        <div className="block-container">
                          <span>Total pending orders</span>
                        </div>

                        <div style={{ marginTop: "10px" }}>
                          <span># 50</span>
                        </div>
                      </div>
                    </div>

                    <div>
                      <div
                        className="total-chart red"
                        onClick={() => setCurrentView("waitlist")}
                      >
                        <div className="block-container">
                          <span>Total rejected orders</span>
                        </div>

                        <div style={{ marginTop: "10px" }}>
                          <span># 12</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="total-chart">
                  <h2>Orders</h2>

                  <div className="table-container">
                    <table className="table fixed-table">
                      <thead className="table-header">
                        <tr>
                          <th>#Order</th>
                          <th>Date</th>
                          <th>Customers</th>
                          <th>Brands</th>
                          <th>Total</th>
                          <th>Status</th>
                        </tr>

                        <tr>
                          <td>1</td>
                          <td>12/12/2021</td>
                          <td>John Doe</td>
                          <td>Apple</td>
                          <td>2000</td>
                          <td>Delivered</td>
                        </tr>

                        <tr>
                          <td>2</td>
                          <td>12/12/2021</td>
                          <td>John Doe</td>
                          <td>Apple</td>
                          <td>2000</td>
                          <td>Delivered</td>
                        </tr>
                      </thead>
                    </table>
                  </div>
                </div>
              </div>
            </>
          </div>
        ) : null}

        {!loading && view === "product" ? (
          <div className="dashboard-container-right">
            <AddModelPopup />
          </div>
        ) : null}
      </div>

      <Footer />
    </div>
  );
};

export default Dashboard;
