import React from "react";
import "../styles/FormPopup.css";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { Form, InputGroup } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const { REACT_APP_API_ENDPOINT } = process.env;

const ChangePassword = ({ setShowPopup }) => {
  const { id } = useParams();
  const [password, setPassword] = React.useState("");
  const [allFieldsFilled, setAllFieldsFilled] = React.useState(false);
  const [pwdChanged, setPwdChanged] = React.useState(false);

  if (password && !allFieldsFilled) {
    setAllFieldsFilled(true);
  } else if (!password && allFieldsFilled) {
    setAllFieldsFilled(false);
  }

  const closePopup = () => {
    setShowPopup(false);
  };

  const submitForm = async (e) => {
    e.preventDefault();

    try {
      await axios.post(`${REACT_APP_API_ENDPOINT}/change-password/${id}`, {
        id,
        password,
      });

      setPwdChanged(true);
      toast.success("Password changed successfully!");
      setTimeout(() => {
        closePopup();
      }, 1500);
    } catch (error) {
      toast.error("Failed to change password. Please try again.");
      console.error("Failed to change password:", error);
    }
  };

  return (
    <>
      <ToastContainer position="top-center" />

      <div className="popupwindow">
        <div className="popup">
          <div className="close-icon-div">
            <FontAwesomeIcon
              icon={faXmark}
              className="close-icon"
              onClick={closePopup}
              size="lg"
            />
          </div>
          <h2>Change password</h2>
          <Form onSubmit={submitForm}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>New password</Form.Label>
              <InputGroup className="mb-3">
                <Form.Control
                  type="password"
                  placeholder="Enter new password"
                  aria-label="Recipient's username"
                  name="password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </InputGroup>
            </Form.Group>
            <button
              type="submit"
              className={`join-btn ${allFieldsFilled ? "black" : "grey"}`}
            >
              {pwdChanged ? (
                <img
                  className="check-mark"
                  src="/check.gif"
                  alt="check"
                  style={{ width: "22px" }}
                />
              ) : (
                "Change password"
              )}
            </button>
          </Form>
        </div>
        <div className="overlay" onClick={closePopup}></div>
      </div>
    </>
  );
};

export default ChangePassword;
