import React, { useEffect } from "react";
import Home from "./Home";

function Admin() {
  useEffect(() => {
    document.title = "Admin login";
  }, []);
  return (
    <div className="Admin">
      <Home page="admin" />
    </div>
  );
}

export default Admin;
