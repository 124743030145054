import React from "react";
import "../styles/Footer.css";

function Footer(props) {
  return (
    <footer className="footer">
      <div className="container">
        <img
          src="https://mingdocs.s3.ca-central-1.amazonaws.com/brand-icon.png"
          alt="brand-icon"
          className="brand-icon-footer"
        />

        <p className="text-muted">&copy; 2024 Hyffin. All rights reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;
